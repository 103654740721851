import React, { Component } from 'react';
import * as d3Shape from 'd3-shape';
import * as d3Scale from 'd3-scale';

import { Tooltip, ChartProvider, XAxis, YAxis, AreaSeries } from 'rough-charts';

class BellCurve extends Component {
    bellEq(x) {
        let std = this.props.standardDeviation;
        let avg = this.props.average;
        
        let a = x - avg;
        return Math.exp( -( a * a ) / ( 2 * std * std ) ) / ( Math.sqrt( 2 * Math.PI ) * std );
    }

    render () {

        let curve = d3Shape.curveCatmullRom.alpha(1);

        //calculate 7 points
        //-3std until + 3std (or 0 when that is the smallest)

        let belowAvg = [];
        let avg = [];
        let aboveAvg = [];

        let maxX = this.props.maxValue;
        let xPoints = [0, maxX];

        let currentX = this.props.average - 3 * this.props.standardDeviation;
        for (let i=currentX; i<= maxX; i+= this.props.standardDeviation / 2) {
            if (i > 0 && i < maxX) xPoints.push(i);
        }
        
        xPoints.sort(function(a, b){return a-b});
        xPoints.forEach(x => {
            let v = this.bellEq(x);

            let dataPoint = { X : x };

            if (x <= this.props.average - this.props.standardDeviation + 0.0001) {
                dataPoint.Y = v;
                belowAvg.push(dataPoint);
                
            }

            if (x >= this.props.average - this.props.standardDeviation - 0.0001 &&
                x <= this.props.average + this.props.standardDeviation + 0.0001) {
                dataPoint.Y = v;
                dataPoint.Tooltip = `${Math.round(x * 10)/10} ${this.props.xTitle}`
                avg.push(dataPoint);
                
            }
            if (x >= this.props.average + this.props.standardDeviation - 0.0001) {
                dataPoint.Y = v;
                aboveAvg.push(dataPoint);
            }
        });

        const xScale = d3Scale
          .scaleLinear()
          .domain([0, maxX]);

        return <div className="bellcurve chart">
            <h3>{this.props.title}</h3>

             <ChartProvider
                    xScale={xScale}
                    height={400}
                    width={1000}
                    data={avg}>
                        <AreaSeries
                            dataKey="Y"
                            data={belowAvg}
                            curve={curve}
                            options={{
                                stroke: '#dddddd',
                                strokeWidth: 1,
                            }}>
                            {() => null}
                        </AreaSeries>

                        <AreaSeries
                            dataKey="Y"
                            data={aboveAvg}
                            curve={curve}
                            options={{
                                stroke: '#dddddd',
                                strokeWidth: 1,
                            }}>
                            {() => null}
                        </AreaSeries>

                        <AreaSeries
                            dataKey="Y"
                            curve={curve}
                            options={{
                                stroke: '#1a4193',
                                strokeWidth: 1,
                            }}>
                        </AreaSeries>
                        
                        <XAxis dataKey="X" tickCount={30} />
                        <YAxis format={tick => null} />
                    <Tooltip>{activeItem => activeItem.Tooltip }</Tooltip>
                </ChartProvider>
                <p><span style={{ color : '#1a4193' }}>&#x25CF; </span><span> Normaal: </span>
                {Math.round(Math.max(this.props.average - this.props.standardDeviation, 0) * 10)/10}
                <span> tot </span>
                {Math.round((this.props.average + this.props.standardDeviation) * 10)/10} {this.props.xTitle}
                <br />
                &nbsp;({Math.round(this.props.average * 10)/10} +/- {Math.round(this.props.standardDeviation * 10)/10} {this.props.xTitle})
            </p>
        </div>;
    }
}

export default BellCurve;