import React, { Component } from 'react';
import { ArcSeries, Tooltip, ChartProvider, Arc } from 'rough-charts';

class StockReservationChart extends Component {
    render () {
        let backorder = this.props.backorder;
        let maxValue = Math.max(this.props.stock + backorder, this.props.reserved);
        
        let data = [
            { name : "Stock", stock : this.props.stock, reserved : this.props.reserved },
            { name : "In backorder", stock: backorder, reserved : 0},
            { name : "Te kort", stock: maxValue - this.props.stock - backorder, reserved : maxValue - this.props.reserved}
        ];

        let stockColors = ['#2e8231', '#6561aa', '#ff0000'];
        let reservationColors = ['#c68035', '#dddddd', '#dddddd'];

        return <div className="stockreservation chart">
                    <h3>{this.props.title}</h3>

                    <ChartProvider
                        height={600}
                        width={1000}
                        data={data}
                    >
                        <ArcSeries
                        dataKey="stock"
                        innerRadiusPercent={0.6}
                        >
                        {(item, itemProps, index) => (
                            <Arc
                            key={index}
                            {...itemProps}
                            options={{ fill: stockColors[index % stockColors.length] }}
                            />
                        )}
                        </ArcSeries>
                        <ArcSeries
                        dataKey="reserved"
                        innerRadiusPercent={0.3}
                        outerRadiusPercent={0.55}
                        >
                        {(item, itemProps, index) => (
                            <Arc
                            key={index}
                            {...itemProps}
                            options={{ fill: reservationColors[index % reservationColors.length] }}
                            />
                        )}
                        </ArcSeries>       
                        <Tooltip />                 
                    </ChartProvider>
                    <ul>
                        <li>{this.props.stock} in stock</li>
                        <li>{this.props.backorder} in backorder</li>
                        <li>{this.props.reserved} in reservation</li>
                        {data[2].stock > 0 ? <li style={{ color : 'red'}}>Tekort: {data[2].stock}</li> : null}
                    </ul>

                    {this.props.children}
                </div>;
    }

}

export default StockReservationChart;