import React from 'react';
import PropTypes from 'prop-types';

export default (Component) => {
    class Translate extends React.Component {
        render() {
          const {forwardedRef, ...rest} = this.props;

          rest.translate = this.context.translate;
          rest.locale = this.context.locale;
          
          // Assign the custom prop "forwardedRef" as a ref
          return <Component ref={forwardedRef} {...rest} />;
        }
      }

    Translate.contextTypes = {
      translate: PropTypes.func.isRequired,
      locale: PropTypes.string.isRequired
    };

    return React.forwardRef((props, ref) => {
        return <Translate {...props} forwardedRef={ref} />;
    });
};