import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import Home from './components/screens/home';
import ProductOverview from './components/screens/product/overview';

import { Route, Switch, Redirect } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import './styles/main.scss'

const RedirectToHome = () => (
  <Redirect to="/" />
)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App>
        <Switch>
              <Route name="home" exact path='/' component={Home} />
              <Route name="productOverview" path='/p/:productId/' component={ProductOverview} />

              <Route component={RedirectToHome} />
          </Switch> 
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
