import React, { Component } from 'react';
import { BarSeries, Tooltip, ChartProvider, XAxis, YAxis } from 'rough-charts';
import * as d3Scale from 'd3-scale';

class PurchaseHistoryChart extends Component {
    render () {
        let ordersMade = this.props.ordersMade;
        let xScale = d3Scale
                        .scaleLinear()
                        .domain([-31, 1]);
        
        let data = ordersMade.map(om => ({
            x : - om.daysAgo,
            sales : om.count
        }));

        xScale.originalTicks = xScale.ticks;
        xScale.ticks = () => xScale.originalTicks(31); 
        
        return <div className="purchasehistory chart">
        <h3>{this.props.title}</h3>
                <ChartProvider
                    xScale={xScale}
                    
                    data={data}
                    height={400}
                    width={1000}>
                    <YAxis />
                    <XAxis dataKey="x" />
                    
                    <BarSeries dataKey="sales" options={{ fill: '#1a4193', fillStyle: 'cross-hatch' }} />
                    <Tooltip />
                </ChartProvider>
            </div>
    }
}

export default PurchaseHistoryChart;