import { Children } from 'react';
import PropTypes from 'prop-types';
import Polyglot from 'node-polyglot';

import { withContext } from 'recompose';
import labels from './labels';

const withI18nContext = withContext({
    locale: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
}, ({ locale }) => {
    const polyglot = new Polyglot({
        locale,
        phrases: labels[locale],
    });

    const translate = polyglot.t.bind(polyglot);    
    return { locale, translate };
});

export const I18nProvider = ({ children }) => Children.only(children);

export default withI18nContext(I18nProvider);