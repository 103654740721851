import React, { Component } from 'react';
import translate from '../../i18n/translate';

class Header extends Component {
    render () {
        return <header>
                    <a href="https://gadero.nl"><img alt="logo" src={process.env.PUBLIC_URL + '/img/logo_gadero_wit.png'} className="no-print" /></a>
                    <img alt="logo" src={process.env.PUBLIC_URL + '/img/logo_gadero_print.png'} className="print-only" />    
                    {this.props.title }                
                </header>;
    }
}

export default translate(Header);