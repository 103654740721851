export default {
    nl : {
        languages : {
            nl : 'Nederlands',
            de : 'Duits (Deutsch)',
            en : 'Engels (English)',
            fr : 'Frans (Français)',
            sp : 'Spaans (Español)',            
        }
    },

    en : {
        languages : {
            nl : 'Dutch (Nederlands)',
            de : 'German (Deutsch)',
            en : 'English',
            fr : 'French (Français)',
            sp : 'Spanish (Español)',            
        }
    },

    fr : {
        languages : {
            nl : 'Néerlandais (Nederlands)',
            de : 'Allemand (Deutsch)',
            en : 'Anglais (English)',
            fr : 'Français',
            sp : 'Espagnol (Español)',            
        }
    },

    de : {
        languages : {
            nl : 'Niederländisch (Nederlands)',
            de : 'Deutsch',
            en : 'Englisch (English)',
            fr : 'Französisch (Français)',
            sp : 'Spanisch (Español)',            
        }
    },

    sp : {    
        languages : {
            nl : 'Holandés (Nederlands)',
            de : 'Alemán (Deutsch)',
            en : 'Inglés (English)',
            fr : 'Francés (Français)',
            sp : 'Español',            
        }
    }  
}