import React, { Component } from 'react';
import Async from "react-async"
import translate from '../../../../i18n/translate';
import { GetProductOverviewData } from '../../../../services/productCatalogService';

import BellCurve from '../../../charts/bellCurve';
import StockReservationChart from '../../../charts/stockReservationChart';
import PurchaseHistoryChart from '../../../charts/purchaseHistory';


import './productoverview.scss'
import moment from 'moment';

class ProductOverview extends Component {
    constructor(props) {
        super(props);        
        
        this.getProductOverviewData = this.getProductOverviewData.bind(this);        
    } 

    getProductOverviewData() {
        let productId = this.props.match.params.productId;
        return GetProductOverviewData(productId);
    }

    render () {
        var groupBy = function(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };


        return <div className="productOverview">        
                <Async promiseFn={this.getProductOverviewData}>
                <Async.Loading> ... </Async.Loading>
                <Async.Resolved>{data => {
                    let maxOrderValue = Math.round( Math.max( data.avgOrdered30 + 2 * data.stdOrdered30, data.avgOrdered60 + 2 * data.stdOrdered60 ) );
                    const reducer = (accumulator, currentValue) => accumulator + currentValue.reservation;
                    let reservationCount = data.reservation.reduce(reducer, 0);

                    const reducer2 = (accumulator, currentValue) => accumulator + currentValue.count;
                    let backorderCount = data.openPurchases.reduce(reducer2, 0);

                    data.openPurchases.sort((a, b) => moment(a.createdOn) - moment(b.createdOn));
                    let grouped = groupBy(data.reservation, 'zipRangeArea');
                    let zipranges = Object.keys(grouped);
                    zipranges.sort();
                    
                    return <>
                        <h2>{data.name}</h2>
                        <div className="charts">
                            <div className="details">
                                <dl>
                                    <dt>ArticleNumber</dt>
                                    <dd>{data.articleNumber}</dd>

                                    <dt>ProductNumber</dt>
                                    <dd>{data.productNumber}</dd>

                                    <dt>Product in OM</dt>
                                    <dd><a href={`https://om.gdro.nl/products/${this.props.match.params.productId}/open`} target="_blank" rel="noopener noreferrer">
                                        https://om.gdro.nl/products/{this.props.match.params.productId}/open
                                        </a></dd>
                                </dl>
                            </div>
                            
                            <div className="details">
                                Leveranciers
                                <ul>
                                    {data.suppliers.map((s, i) => <li key={i}>
                                        {s.name}
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="charts">
                            {data.stdDeliveryInDays > 0 &&
                            <BellCurve
                                title="Delivery in days"
                                xTitle="days"
                                maxValue={60}
                                average={data.avgDeliveryInDays}
                                standardDeviation={data.stdDeliveryInDays} />}

                            <div className="details">
                                <ul>
                                    {data.openPurchases.map((p, i) => <li key={i}>
                                        <a href={`https://om.gdro.nl/admin/supplier_purchase/order/${p.uuid}`} target="_blank" rel="noopener noreferrer">
                                            {p.count} ordered with {p.supplier} on {p.createdOn}
                                        </a>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        
                        <div className="charts3">
                            <PurchaseHistoryChart
                                title="Ordered last 30 days"
                                ordersMade={data.ordersMade}
                                />

                            {data.stdOrdered30 > 0 &&
                            <BellCurve
                                title="Average last 30 days"
                                xTitle="articles/day"
                                maxValue={ maxOrderValue }
                                average={data.avgOrdered30}
                                standardDeviation={data.stdOrdered30} />}

                            {data.stdOrdered60 > 0 &&
                            <BellCurve
                                title="Average last 60 days"
                                xTitle="articles/day"
                                maxValue={ maxOrderValue }
                                average={data.avgOrdered60}
                                standardDeviation={data.stdOrdered60} />}
                        </div>

                        <div className="charts">
                            <StockReservationChart
                                title="Stock vs Reservations"
                                stock={data.stock}
                                reserved={reservationCount}
                                backorder={backorderCount}
                            >
                                <p>
                                    Expected sales in {Math.round(data.avgDeliveryInDays)} days: {Math.round(data.avgDeliveryInDays * data.avgOrdered30)} based on the 30 day average<br />
                                    {data.stock + backorderCount > reservationCount + Math.round(data.avgDeliveryInDays * data.avgOrdered30) ? 'For now OK' : 'Need to order more?' }
                                </p>
                            </StockReservationChart>

                            <div className="details">
                                <ul>
                                    {zipranges.map((p, i) => <li key={i}>
                                        {p}
                                        <ul>
                                            {grouped[p].map((r, j) => <li key={j}>
                                                <a href={`https://om.gdro.nl/orders/${r.orderId}/open`} target="_blank" rel="noopener noreferrer">
                                                    {r.reservation} ordered on {r.orderDate} (OrderId: {r.orderId})
                                                </a>
                                            </li>)}
                                        </ul>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </>
                }}</Async.Resolved>
                </Async>
            </div>;
    }
}

export default translate(ProductOverview);