import React, { Component } from 'react';
import Header from './components/parts/header';

import I18nProvider from './i18n/i18nProvider';

class App extends Component {
  render() {
    let locale = 'nl';

    return (
      <I18nProvider locale={locale}>
      <>
        <Header title="Product Catalog" />

        <main>
          {this.props.children}
        </main>
      </>
      </I18nProvider>
    );
  }
}

export default App;
